<template>
  <div id="sku_box" ref="sku_box" style="width:100%;height:100%">
    <div id="sku_echart" style="width:100%;height:100%"></div>
     <notData v-if="propData.length == 0" />
  </div>
</template>
<script>
export default {
  props: {
    propData: {
      type: Array,
      default: () => []
    },
    // 报修1  开箱不良2
    dimensional: {
      type: Number,
      default: 2
    },
    orderType: { // 1不良数量倒序  2 不良比列倒序
      type: Number,
      default: 1
    },
    cycleType: { // 2按发货  1按报修
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      data: []
    }
  },
  watch: {
    propData: {
      handler (n) {
        this.data = n
        // 一开始高度是为0，获取数据后重新获取新的高度（因为盒子的高度是动态获取的）
        this.resizeCharts()
        this.init()
      },
      deep: true
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizeCharts, true)
    this.$erd('sku_box', 'sku_echart')
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeCharts, true)
  },
  methods: {
    resizeCharts () {
      const myChart = this.$echarts.init(document.getElementById('sku_echart'))
      myChart.resize()
    },
    handleShowText (params, data2, data3) {
      let str = ''
      if (this.cycleType === 2) {
        if (this.dimensional === 1) {
          if (this.orderType === 2) {
            str = `报修率:${params.value}%\n发货量:${data3[params.dataIndex]}\n报修:${data2[params.dataIndex]}`
          } else {
            str = `报修:${params.value}\n发货量:${data3[params.dataIndex]}`
          }
        } else {
          if (this.orderType === 2) {
            str = `不良率:${params.value}%\n发货量:${data3[params.dataIndex]}\n开箱不良:${data2[params.dataIndex]}`
          } else {
            str = `开箱不良:${params.value}\n发货量:${data3[params.dataIndex]}`
          }
        }
      } else {
        str = params.value
      }
      return str
    },
    init () {
      const myChart = this.$echarts.init(
        document.getElementById('sku_echart')
      )
      let dataY = []
      let data1 = []
      let data2 = []
      let data3 = []
      if (this.data.length > 0) {
        dataY = this.data.map(item => item.name.split('（')[0]).reverse()
        data1 = this.data.map(item => item.value).reverse()
        data2 = this.data.map(item => item.num || '').reverse()
        data3 = this.data.map(item => item.deliverNum || '').reverse()
      }
      const option = {
        legend: {
          left: '10%',
          top: '10'
        },
        grid: {
          left: '1%',
          right: '30%',
          bottom: '0',
          top: '20',
          containLabel: true
        },
        color: this.dimensional === 1 ? '#5470c6' : '#fac858',
        // color: [
        //   '#5470c6',
        //   '#fac858'
        // ],
        xAxis: [
          {
            type: 'value',
            axisTick: {
              // 坐标轴刻度相关设置。
              show: false
            },
            axisLine: {
              // 坐标轴轴线相关设置
              show: false,
              lineStyle: {
                color: '#95A3B6',
                opacity: 1
              }
            },
            splitLine: {
              // 坐标轴在 grid 区域中的分隔线。
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#ddd'
              }
            },
            axisLabel: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            axisTick: {
              show: false
            },
            data: dataY,
            axisLine: {
              show: false,
              lineStyle: {
                color: '#95A3B6'
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
                color: '#ddd'
              }
            },
            // axisLabel: {
            //   color: '#000',
            //   fontSize: 14,
            // }
            axisLabel: {
              interval: 0,
              color: '#000',
              fontSize: 14,
              formatter: function (value) {
                var ret = ''// 拼接加\n返回的类目项
                var maxLength = 8// 每项显示文字个数
                var valLength = value.length// X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength) // 类目项需要换行的行数
                if (rowN > 1) { // 如果类目项的文字大于3,
                  for (var i = 0; i < rowN; i++) {
                    var temp = ''// 每次截取的字符串
                    var start = i * maxLength// 开始截取的位置
                    var end = start + maxLength// 结束截取的位置
                    // 这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + '\n'
                    ret += temp // 凭借最终的字符串
                  }
                  return ret
                } else {
                  return value
                }
              }
            }
          }
        ],
        series: [
          {
            name: this.dimensional === 2 ? '开箱不良' : '报修台数',
            type: 'bar',
            barWidth: '40px',
            label: {
              show: true,
              position: 'right',
              textStyle: {
                color: '#000'
              },
              formatter: (params) => {
                return this.handleShowText(params, data2, data3)
                // return +params.value ? `${this.orderType === 2 ? params.value + '%' : params.value}\n发货量:${data3[params.dataIndex]}\n${data2[params.dataIndex]}` : ''
              }
            },
            data: data1
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>
