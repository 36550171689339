<template>
  <div class="layout_common sku">
    <div class="main_box">
      <div class="main_box_con">
        <searchHead
          :compType="compTypeToHead"
          :storeInfo="storeInfo"
          @search="search"
          @reset="search"
          @rankClick="isShow = true"
          isExportBtn
          @exportData=exportData
        />
        <div
          class="echart"
          :style="{ height: echartHeight + 'px' }"
          v-loading="loading"
        >
          <div class="bar">
            <barEchart
              :propData="echartBarData"
              :dimensional="dimensional"
              :orderType="orderType"
              :cycleType="cycleType"
            />
          </div>
          <ul class="pie" v-if="this.compType !== 'store-unpack-sku'">
            <div class="title">商家</div>
            <li v-for="(item, index) in merchantList" :key="index">
              <pieEchart
                radius="80%"
                :center="['20%', '50%']"
                :id1="`sku_pie1_box${index}`"
                :id2="`sku_pie1_echart${index}`"
                :propData="item"
              />
            </li>
            <notData v-if="faultList.length == 0 || storeId" />
          </ul>
          <ul class="pie">
            <div class="title">问题归类</div>
            <li v-for="(item, index) in faultList" :key="index">
              <pieEchart
                radius="80%"
                :id1="`sku_pie2_box${index}`"
                :id2="`sku_pie2_echart${index}`"
                :propData="item"
              />
            </li>
            <notData v-if="faultList.length == 0" />
          </ul>
          <ul class="pie">
            <div class="title">开箱不良原因</div>
            <li v-for="(item, index) in reasonList" :key="index">
              <pieEchart
                radius="80%"
                :center="['25%', '50%']"
                :id1="`sku_pie3_box${index}`"
                :id2="`sku_pie3_echart${index}`"
                :propData="item"
              />
            </li>
            <notData v-if="reasonList.length == 0 || dimensional === 1" />
          </ul>
          <ul class="pie">
            <div class="title">责任归属</div>
            <li v-for="(item, index) in dutyList" :key="index">
              <pieEchart
                legendRight="15%"
                :center="['35%', '50%']"
                :id1="`sku_pie4_box${index}`"
                :id2="`sku_pie4_echart${index}`"
                :propData="item"
              />
            </li>
            <notData v-if="dutyList.length == 0" />
          </ul>
        </div>
        <div class="pagination" v-if="total > 10">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="curPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            >>
          </el-pagination>
        </div>
      </div>
    </div>
     <!-- 商家发货排名 -->
    <rank
      :isShow="isShow"
      @close="isShow = false"
      @selectStore="selectStore"
    />
  </div>
</template>
<script>
import searchHead from '@/views/data-center/components/searchHead'
import barEchart from './bar'
import pieEchart from './pie'
import rank from '../components/rank'
import { skuData, skuExportData } from '@/api'
import { formatDate, diffDate } from '@/utils/common'
export default {
  components: {
    barEchart,
    pieEchart,
    searchHead,
    rank
  },
  props: {
    // 来区分 周期类型 1：按报修时间 2:按发货日期
    cycleType: {
      type: Number,
      default: 1
    },
    // 那个组件引用的
    compType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isShow: false,
      storeInfo: {},
      curPage: 1,
      pageSize: 10,
      total: 0,
      storeId: '',
      storeName: '',
      startTime: '',
      endTime: '',
      timeType: 3,
      dimensional: 2, // 开箱不良2、报修1
      orderType: 1,
      faultList: [],
      reasonList: [],
      dutyList: [],
      merchantList: [],
      echartBarData: [],
      loading: false
    }
  },
  computed: {
    echartHeight () {
      return this.merchantList.length * 150
    },
    compTypeToHead () {
      let str = ''
      if (this.compType) {
        // 如果有值的话 就取这个值给searchHead这个组件(商家开箱不良-sku统计)
        str = this.compType
      } else {
        // 没有值表示  是sku统计、发货报障页面
        str = this.cycleType === 1 ? 'sku' : 'fault'
      }
      return str
    }
  },
  mounted () {
    if (JSON.stringify(this.$route.query) === '{}') {
      this.endTime = formatDate(new Date()).slice(0, 7)
      this.startTime = diffDate(-5, 'months', true).slice(0, 7)
    } else {
      const { timeType, startTime, endTime } = this.$route.query
      this.timeType = +timeType
      this.startTime = startTime
      this.endTime = endTime
    }
    this.getList()
  },
  methods: {
    getList () {
      this.params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        cycleType: this.cycleType,
        orderType: this.cycleType === 2 ? this.orderType : '',
        timeType: this.timeType,
        startTime: this.startTime,
        endTime: this.endTime,
        storeId:
          this.compType === 'store-unpack-sku'
            ? this.$route.query.storeId
            : this.storeId,
        dimensional: this.dimensional
      }
      this.loading = true
      skuData(this.params).then(res => {
        this.loading = false
        const { list, totalCount } = res.data
        this.total = totalCount
        this.echartBarData = this.handleEchartBarData(
          list,
          this.cycleType,
          this.orderType,
          this.dimensional
        )
        this.merchantList = list.map(item => item.store)
        this.faultList = list.map(item => item.problem)
        this.reasonList = list.map(item => item.poorUnpacking)
        this.dutyList = list.map(item => item.duty)
      })
    },
    // 根据 cycleType、orderType、dimensional来组合不同的数据
    handleEchartBarData (list, cycleType, orderType, dimensional) {
      let echartBarData = []
      if (cycleType === 2) {
        if (orderType === 1) {
          if (dimensional === 2) {
            echartBarData = list.map(item => ({
              name: item.commodityName,
              value: item.poorUnpackingNum,
              deliverNum: item.deliverNum
            }))
          } else {
            echartBarData = list.map(item => ({
              name: item.commodityName,
              value: item.repairNum,
              deliverNum: item.deliverNum
            }))
          }
        } else {
          echartBarData = list.map(item => ({
            name: item.commodityName,
            value: item.rate,
            num: dimensional === 1 ? item.repairNum : item.poorUnpackingNum,
            deliverNum: item.deliverNum
          }))
        }
      } else {
        if (dimensional === 2) {
          echartBarData = list.map(item => ({
            name: item.commodityName,
            value: item.poorUnpackingNum
          }))
        } else {
          echartBarData = list.map(item => ({
            name: item.commodityName,
            value: item.repairNum
          }))
        }
      }
      return echartBarData
    },
    // 选择的商家
    selectStore (val) {
      this.storeId = val.storeId
      this.storeName = val.storeName
      this.storeInfo = {
        storeId: this.storeId,
        storeName: this.storeName
      }
    },
    // 导出
    exportData () {
      skuExportData(this.params)
      this.$message({
        message:
          '已经发起导出任务，请关注个人中心导出任务列表页面，任务完成后可以下载导出文档',
        type: 'warning',
        duration: 5000
      })
    },
    search (val) {
      this.curPage = 1
      const {
        orderType,
        dimensional,
        timeType,
        storeId,
        startTime,
        endTime
      } = val
      this.orderType = orderType
      this.dimensional = dimensional
      this.timeType = timeType
      this.storeId = storeId
      this.startTime = startTime
      this.endTime = endTime
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.sku {
  .header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .export_btn {
      position: relative;
      top: -5px;
    }
  }
  .echart {
    display: flex;
    margin-top: 20px;
    height: 1500px;
    .bar {
      flex: 1.5;
    }
    .pie {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      height: 100%;
      .title {
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);
        font-size: 16px;
      }
      li {
        flex: 1;
        padding: 5px 0;
      }
    }
  }
  .pagination {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
